import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { GrPowerReset } from "react-icons/gr";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AiOutlineAudio, AiOutlineClear, AiOutlineFileProtect, AiOutlineVideoCamera } from "react-icons/ai";

import moment from "moment";
import _, { map, mean, size } from "lodash";
import Pagination from "@choc-ui/paginator";

import "firebase/database";
import "firebase/messaging";
import firebase from "firebase/app";
import { firebaseClient } from "../../firebase";
import { DateRangePicker } from 'rsuite';

import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    IconButton,
    Tag,
    Text,
    useDisclosure,
    List,
    ListItem,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Input,
    InputLeftElement,
    InputGroup,
    VStack,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    TabPanels,
    Divider,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Avatar,
    Select,
    InputRightElement,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Icon,
} from "@chakra-ui/react";
import {
    ArrowDownIcon,
    ArrowUpIcon,
    ChevronRightIcon,
    CloseIcon,
    Search2Icon,
    ChevronDownIcon,
    PhoneIcon,
    CopyIcon,
    CheckCircleIcon,
    ViewIcon,
    ViewOffIcon,
    ChatIcon
} from "@chakra-ui/icons";
import { Box, Heading } from "@chakra-ui/layout";

import {
    closeDoubtAction,
    getAllTeachers,
    getBatchSubjectStatusAction,
    getChatDetailsAction,
    getDoubtsAction,
    getTeacherAssingedAction,
    getTeacherDoubtsAction,
    publicDoubtAction,
    readCommentAction,
    resetReadAction,
    resetTeacherAssignedData,
} from "../../redux/reducers/doubt";

import { STATUS } from "../../Constants";
import { bilingualText, mobileView, webView } from "../../utils/Helper";

import { TeacherTests } from "../TeacherTests";
import { TeacherTests2 } from "../TeacherTests2";
import { TeacherCourses } from "../TeacherCourses";

import { ErrorChecker } from "../../Components/ErrorChecker";

import { DiscussionComments } from "../../routes/DiscussionCommunity/DiscussionComments";
import { BsSlashLg } from "react-icons/bs";
import { ThumbsDownIcon, ThumbsUpIcon } from "@100mslive/react-icons";


export const TeacherChatScreen = (props) => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

    const [active, setActive] = useState();
    const [formKey, changeFormKey] = useState(0);
    const [currentTab, setCurrentTab] = useState("0");
    const [searchDoubt, changeSearchDoubt] = useState();
    const [currentTeacher, setCurrentTeacher] = useState();
    const [testDiscussion, openTestDiscussion] = useState();
    const [courseDiscussion, openCourseDiscussion] = useState();
    const [selectedClasEnd, setSelectedClasEnd] = useState([]);


    const { user, chatDetails, allTeachers, teacherDoubts, closeDoubtStatus, getBatchSubjectLectureStatus } = useSelector((s) => ({
        user: s.user.user,
        // pageConfig: s.doubts.pageConfig,
        chatDetails: s.doubts.chatDetails,
        allTeachers: s.doubts.allTeachers,
        teacherDoubts: s.doubts.teacherDoubts,
        closeDoubtStatus: s.doubts.closeDoubtStatus,
        // getChatDetailsStatus: s.doubts.getChatDetailsStatus,
        // teacherAssignedData: s.doubts.teacherAssignedData || [],
        getBatchSubjectLectureStatus: s.doubts.getBatchSubjectLectureStatus,
    }));






    useEffect(() => {
        dispatch(getChatDetailsAction({ teacherId: user?._id }));
    }, []);

    useEffect(() => {
        if (getBatchSubjectLectureStatus === "SUCCESS") {
            dispatch(getChatDetailsAction({ teacherId: user?._id }));
        }
    }, [getBatchSubjectLectureStatus]);

    useEffect(() => {
        if (allTeachers?.length && params.staffId) {
            const staff = _.find(allTeachers, (t) => t.user?._id === params.staffId);
            setCurrentTeacher(staff);
        }
    }, [allTeachers, params]);

    const checkRole = useMemo(() => {
        return (
            user?.staff?.staffRole === "ADMIN" || user?.staff?.staffRole === "HEAD_TEACHER"
            // user?.staff?.staffRole === "TEACHER"
        );
    }, [user?.staff?.staffRole]);

    useEffect(() => {
        if (params?.staffId) dispatch(getTeacherAssingedAction({ assignedTo: params.staffId }));
        else if (user?.staff) dispatch(getTeacherAssingedAction({ assignedTo: user._id }));
        else dispatch(resetTeacherAssignedData());
    }, [teacherDoubts, dispatch, user, params]);


    const getDoubts = useCallback(() => {
        if (params.page)
            if (params.staffId) {
                dispatch(getTeacherDoubtsAction({ page: params.page, staff: params.staffId, doubt_text_regex: searchDoubt }));
            } else dispatch(getTeacherDoubtsAction({ page: params.page, staff: user?._id, doubt_text_regex: searchDoubt }));
    }, [params.page, params.staffId, dispatch, searchDoubt, user?._id]);

    const getTeachers = useCallback(() => {
        if (checkRole) dispatch(getAllTeachers());
    }, [dispatch, checkRole]);

    useEffect(() => {
        getDoubts();
    }, [getDoubts]);

    useEffect(() => {
        getTeachers();
    }, [getTeachers]);


    useEffect(() => {
        if (closeDoubtStatus === STATUS.SUCCESS && active) {
            setActive(_.find(teacherDoubts, (d) => d._id === active._id));
        }
    }, [closeDoubtStatus, teacherDoubts, active]);

    const _openDoubt = (a) => {
        setActive(a);
        onOpen();

    };

    const handlePageChange = (page) => {
        if (checkRole) history.push(`/doubts/${page}/${params.staffId}`);
        else history.push("/doubts/" + page);
    };

    const handleChangeSearch = (e) => {
        changeSearchDoubt(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        handlePageChange(1);
    };

    const resetSearch = () => {
        changeSearchDoubt(null);
        changeFormKey((d) => d + 1);
        handlePageChange(1);
    };

    const handleTabChange = (e) => {

        setCurrentTab(e.target.value);
    };

    const handleOpenTestDiscussion = (test) => {
        openTestDiscussion((d) => (d ? null : test));
    };

    const handleOpenCourseDiscussion = (content) => {
        openCourseDiscussion((d) => (d ? null : content));
    };
    const handleLiveChange = (chatDetails) => {
        const staff_id = user.staff._id
        const LiveClasses = chatDetails.Live;
        const Live_id = []
        LiveClasses.forEach(element => {
            Live_id.push(element._id)
        });
        const data = {
            staff_id: staff_id,
            class: Live_id
        }
        dispatch(getBatchSubjectStatusAction({ id: data.class, status: "Ended" }));
    }
    const endSelectedClass = () => {
        dispatch(getBatchSubjectStatusAction({ id: selectedClasEnd, status: "Ended" }));
        setSelectedClasEnd([]);
    }
    return (

        <Box>
            <Box className="bottomHeader" p={3} bg="#EFF3F6">
                <div className="row align-items-center ">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        <Heading fontSize="lg">Teacher Chat Room</Heading>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        {checkRole && (
                            <Box {...webView} flex={3}>
                                <MemorizeTeachersList resetSearch={resetSearch} />
                            </Box>
                        )}
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        <Flex>
                            <Box fontSize={[14]}>Teachers: &nbsp;</Box>
                            <Box color={"brand.blue"} fontWeight={"bold"} fontSize={[16]}>
                                {allTeachers?.length || 0}
                            </Box>
                        </Flex>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-xl-0 mb-lg-0 mb-md-2 mb-2 text-end position-relative">
                        {currentTab == "0" && selectedClasEnd?.length > 0 && (
                            <button className="btn btn-secondary me-2" onClick={() => { endSelectedClass(selectedClasEnd) }}>
                                End Selected Class
                            </button>
                        )}
                        {currentTab == "0" && chatDetails && chatDetails.Live.length !== 0 ? <button
                            onClick={() => handleLiveChange(chatDetails)}
                            className="btn btn-secondary position-static"
                        >
                            End All
                        </button> : <></>}
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        <Box w={["100%", "100%", "100%", "100%"]}>
                            <Select borderColor={["#C1C2C2"]} value={currentTab} onChange={handleTabChange} placeItems={"Select appointment status"}>
                                <option value={0}>Live</option>
                                <option value={1}>History</option>
                                <option value={2}>Upcoming</option>
                            </Select>
                        </Box>
                    </div>
                </div>
            </Box>

            <Flex align={"stretch"}>
                <Box flex={10}>
                    <Box>
                        {checkRole && (
                            <>
                                <br />
                                <MobileTeacherList resetSearch={resetSearch} />
                            </>
                        )}
                        <Box px={[1, 1, 1, 5]} my={5} mt={0}>
                            <Text fontSize="lg">{currentTeacher?.user?.name}</Text>
                            <Text color={"brand.secondary"}>{currentTeacher?.staffDesc}</Text>
                            <br />
                            <Box
                                className=""
                                mt={0}
                            >
                                {currentTab === "0" && (
                                    <HStack alignItems={"stre"} width={"100%"}>
                                        <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                                            {chatDetails && chatDetails.Live && chatDetails.Live.map((chat) =>
                                                <LiveChatList
                                                    key={chat._id}
                                                    chat={chat}
                                                    setSelectedClasEnd={setSelectedClasEnd}
                                                    selectedClasEnd={selectedClasEnd}
                                                    youtubeVideoId={chat?.youtubeVideoId}
                                                />
                                            )}
                                        </div>
                                    </HStack>
                                )}
                                {currentTab === "1" && <HistoryList chatDetails={chatDetails} />}
                                {currentTab === "2" && (
                                    <HStack alignItems={"stre"} width={"100%"}>
                                        <Box flex={4}>
                                            <UpcomingList chatDetails={chatDetails} />
                                        </Box>
                                    </HStack>
                                )}
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

const LiveChatList = ({ chat, setSelectedClasEnd, selectedClasEnd, youtubeVideoId }) => {
    const dispatch = useDispatch();
    const chatContainerRef = useRef(null);
    const [mentorMessage, setMentorMessages] = useState("");
    const [liveChatMessages, setLiveChatMessages] = useState([]);
    // const [selectedClasEnd, setSelectedClasEnd] = useState([]);  
    const [replyModalOpen, setReplyModalOpen] = useState(false);
    const [replyMessage, setReplyMessage] = useState("")
    const [msgId, setMsgId] = useState("")
    const [ytData, setYtData] = useState([{
        viewCount: "1234567",
        likeCount: "23456",
        dislikeCount: "789",
        commentCount: "1234"
    }])

    const { user, getBatchSubjectLectureStatus } = useSelector((s) => ({
        user: s.user.user,
        getBatchSubjectLectureStatus: s.doubts.getBatchSubjectLectureStatus,
    }));





    useEffect(() => {
        if (youtubeVideoId) {
            const fetchYtData = async () => {
                const apiKey = 'YOUR_YOUTUBE_API_KEY';  // Replace with your actual API key
                const url = `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${youtubeVideoId}&key=${apiKey}`;

                try {
                    const response = await fetch(url);
                    const data = await response.json();

                    if (data.items && data.items.length > 0) {
                        const videoStats = data.items[0].statistics;
                        setYtData(videoStats);  // Set the video statistics to state
                    } else {
                        console.error("Video not found or no statistics available.");
                    }
                } catch (error) {
                    console.error("Error fetching YouTube video statistics:", error);
                }
            };

            fetchYtData();
        }
    }, [youtubeVideoId]);



    useEffect(() => {
        if (chat) {

            const chatRoomId = chat?.chatRoomId;
            const chatRoomRef = firebaseClient.database().ref(chatRoomId);

            chatRoomRef.on("value", (snapshot) => {
                const chatData = snapshot.val();
                if (chatData) {
                    const chatMessages = Object.values(chatData);
                    setLiveChatMessages(chatMessages);

                    if (chatContainerRef.current) {
                        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                    }
                }
            });

            return () => {
                chatRoomRef.off("value");
            };
            // }
        }
    }, [chat]);

    useEffect(() => {
        if (getBatchSubjectLectureStatus === "SUCCESS") {
            dispatch(getChatDetailsAction({ teacherId: user?._id }));
        }
    }, [getBatchSubjectLectureStatus]);

    const handleEndClass = (e) => {
        dispatch(getBatchSubjectStatusAction({ id: [chat?._id], status: "Ended" }));
    };

    const endSelectedClass = (classId) => {
        setSelectedClasEnd((prev) => {
            if (prev.includes(classId)) {
                return prev.filter(id => id !== classId);
            } else {
                return [...prev, classId];
            }
        });
    };
    const handleMentorMessages = () => {

        if (mentorMessage.trim()) {
            const newMessage = {
                _id: Math.floor(10000 + Math.random() * 90000),
                text: mentorMessage,
                timestamp: moment().format(),
                user: { avatar: user?.avatar, name: user?.name || "Mentor" },
                replies: []
            };

            setLiveChatMessages((prevMessages) => [...prevMessages, newMessage]);
            setMentorMessages("");

            const chatRoomId = chat?.chatRoomId;
            const chatRoomRef = firebaseClient.database().ref(chatRoomId);
            chatRoomRef.push(newMessage);
        }
    }
    function findKeyById(obj, id) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key]._id && obj[key]._id === id) {
                return key;
            }
        }
        return null;
    }
    const handleReply = (msgid, replyText = "jjjj") => {
        setReplyModalOpen(true)
        setMsgId(msgid)

    }

    const handleReplyMessage = () => {
        const chatRoomId = chat?.chatRoomId;
        const chatRoomRef = firebaseClient.database().ref(chatRoomId);

        chatRoomRef.once("value", (snapshot) => {
            const messageData = snapshot.val();
            const messageKeys = Object.keys(messageData);
            const key = findKeyById(messageData, msgId)

            if (key) {
                const RoomRef = firebaseClient.database().ref(chatRoomId).child(`${key}`);
                const reply = {
                    text: replyMessage,
                    timestamp: new Date().toISOString(),
                    user: {
                        avatar: user?.avatar,
                        name: user?.name,
                    },
                };
                RoomRef.child("replies").push(reply);
            } else {
                console.error(`Message ID ${msgId} not found`);
            }

        })
        setMsgId("")
    }

    return (
        <div style={{ border: "1px solid #e2e8f0", borderRadius: "15px", maxWidth: "100%", minWidth: "33.1%" }}>
            {liveChatMessages?.length > 0 && (
                <Flex mb={2} align="center" justifyContent="space-between" style={{ padding: "15px 15px 5px 15px", borderBottom: "1px solid #e2e8f0" }}>
                    <input id={chat?._id} type="checkbox" name="begin-class-checkbox"
                        onChange={(e) => { endSelectedClass(chat?._id) }}
                        style={{
                            width: "18px",
                            height: "18px",
                            display: "inline-block",
                            marginRight: "12px",
                            marginLeft: "5px",
                            cursor: "pointer "
                        }}
                    />
                    <Text>{chat?.batch}</Text>
                    <Button onClick={(e) => handleEndClass(e)}>End Class</Button>
                </Flex>
            )}
            <div ref={chatContainerRef} style={{ height: "60dvh", overflow: "auto", padding: "15px 15px 5px 15px", fontSize: "12px" }} id="commentBox">
                {liveChatMessages?.length > 0 && chat?.chatRoomId !== undefined ? (
                    liveChatMessages?.map((message, i) => (
                        <Flex key={i} align="center" my={2}>
                            <Box mr={2}>
                                <Avatar style={{ height: "30px", width: "30px" }} src={message?.user?.avatar} name={message?.user?.name} />
                            </Box>
                            <Box>
                                <Text fontWeight="bold">{message?.user?.name}</Text>
                                <Text>{message?.text}</Text>
                                <Text fontSize="sm" color="gray.500">
                                    {moment(message?._id).format("MMMM Do YYYY, h:mm:ss a")}
                                </Text>
                                <Button size="xs" onClick={() => handleReply(message._id)}>Reply</Button>

                                {message?.replies !== undefined ? (
                                    <Box mt={2} pl={4} borderLeft="1px solid #e2e8f0">
                                        <Text fontSize="sm" color="gray.500">Replies:</Text>
                                        {Object.entries(message.replies).map(([key, reply]) => (
                                            <Flex key={key} align="center" my={1}>
                                                <Box mr={2}>
                                                    <Avatar style={{ height: "30px", width: "30px" }} src={reply?.user?.avatar} name={reply?.user?.name} />
                                                </Box>
                                                <Box>
                                                    <Text fontWeight="bold">{reply?.user?.name}</Text>
                                                    <Text>{reply?.text}</Text>
                                                    <Text fontSize="sm" color="gray.500">
                                                        {moment(reply?._id).format("MMMM Do YYYY, h:mm:ss a")}
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        ))}
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </Flex>
                    ))
                ) : (
                    <Text textAlign={"center"} color={"#b2b6bc"}>
                        No Data Available
                    </Text>
                )}
            </div>
            <Flex mt={2}>
                <Input
                    placeholder="Type your message..."
                    value={mentorMessage}
                    onChange={(e) => setMentorMessages(e.target.value)}
                />
                <Button ml={2} onClick={handleMentorMessages} >
                    Send
                </Button>
            </Flex>
            <Modal isOpen={replyModalOpen} onClose={() => setReplyModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Reply to Message</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            placeholder="Type your reply..."
                            value={replyMessage}
                            onChange={(e) => setReplyMessage(e.target.value)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={() => setReplyModalOpen(false)}>Cancel</Button>
                        <Button colorScheme="blue" onClick={() => {
                            handleReplyMessage();
                            setReplyModalOpen(false);
                            setReplyMessage('');
                        }}>Reply</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Flex
                direction={["column", "row"]}
                mt={4}
                justify="space-between"
                ml={3}
                mb={3}
            >
                <div style={{ display: "flex", width: "100%", maxWidth: "22%", alignItems: "center" }}>
                    <ViewIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                    <Text fontWeight="bold" fontSize="sm">Views:</Text>
                    <Text fontSize="sm" color="gray.700" ml={2}>5000</Text>
                </div>
                <div style={{ display: "flex", width: "100%", maxWidth: "22%", alignItems: "center" }}>
                    <ThumbsUpIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                    <Text fontWeight="bold" fontSize="sm">Likes:</Text>
                    <Text fontSize="sm" color="gray.700" ml={2}>234</Text>
                </div>
                <div style={{ display: "flex", width: "100%", maxWidth: "33%", alignItems: "center" }}>
                    <ChatIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                    <Text fontWeight="bold" fontSize="sm">Comments:</Text>
                    <Text fontSize="sm" color="gray.700" ml={2}>12</Text>
                </div>
                <div style={{ display: "flex", width: "100%", maxWidth: "33%", alignItems: "center" }}>
                    <ThumbsDownIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                    <Text fontWeight="bold" fontSize="sm">Dislikes:</Text>
                    <Text fontSize="sm" color="gray.700" ml={2}>22</Text>
                </div>
            </Flex>
        </div>
    );
};
const HistoryList = ({ chatDetails, searchDoubt, handleChangeSearch }) => {
    const [roomData, setRoomData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortedChatHistoryDetails, setSortedChatHistoryDetails] = useState([]);
    const [selectedChatRoom, setSelectedChatRoom] = useState(null);

    useEffect(() => {
        const _chatDetails = [...chatDetails.Ended];
        if (_chatDetails) {
            _chatDetails.sort((b, a) => new Date(a.scheduleDateTime) - new Date(b.scheduleDateTime));
            setSortedChatHistoryDetails(_chatDetails);
        }
    }, [chatDetails?.Ended]);

    const chatRoomChatDetails = async (chatRoomId) => {


        if (chatRoomId === undefined) {
            return;
        } else {
            try {
                setIsLoading(true);
                const snapshot = await firebaseClient.database().ref(chatRoomId).once("value");
                const data = snapshot.val();
                const dataArray = Object.values(data);
                setRoomData(dataArray);
                setIsLoading(false);
                setSelectedChatRoom(chatRoomId);
            } catch (error) {
                console.error("Error fetching chat details:", error);
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="chatbox-block">
            <div className="row">
                {/* Sidebar for lectures */}
                <div className="col-sm-4 lecture-left-block">
                    <div className="seactch-dater-filter">
                        <div className="search-section">
                            <InputGroup>
                                <InputRightElement pointerEvents="none" children={<Search2Icon color="#4559d2" />} />
                                <Input value={searchDoubt} name="text" onChange={handleChangeSearch} placeholder="Search Lecture" />
                            </InputGroup>
                        </div>
                        <div className="date-section">
                            <InputGroup>
                                <Input class="form-control form-control-solid" placeholder="Pick date rage" id="kt_daterangepicker_4" />
                            </InputGroup>
                        </div>
                    </div>
                    <div>
                        {sortedChatHistoryDetails?.length > 0 &&
                            sortedChatHistoryDetails.map((endedItem, i) => (
                                <div
                                    className="lecture-box"
                                    key={i}
                                    onClick={() => chatRoomChatDetails(endedItem?.chatRoomId)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="lecture-content">
                                        <b>{endedItem.lectureName}</b>
                                        <p>{endedItem.batch}</p>
                                    </div>
                                    <div className="lecture-date-time">
                                        <span>{moment(endedItem.scheduleDateTime).format('DD/MM/YYYY')}</span>
                                        <span>{moment(endedItem.scheduleDateTime).format('HH:mm')}</span>
                                    </div>
                                    <Flex
                                        direction={["column", "row"]}
                                        justify="space-between"      
                                        align="center"               
                                        mt={4}
                                        mb={3}
                                        wrap="wrap"
                                        width="100%"
                                    >
                                        <Flex
                                            direction="row"
                                            align="center"
                                            width={["100%", "22%"]}
                                            justify="flex-start"
                                        >
                                            <ViewIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                                            <Text fontWeight="bold" fontSize="sm">Views:</Text>
                                            <Text fontSize="sm" color="gray.700" ml={2}>5000</Text>
                                        </Flex>
                                        <Flex
                                            direction="row"
                                            align="center"
                                            width={["100%", "22%"]}   
                                            justify="flex-start"
                                        >
                                            <ThumbsUpIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                                            <Text fontWeight="bold" fontSize="sm">Likes:</Text>
                                            <Text fontSize="sm" color="gray.700" ml={2}>234</Text>
                                        </Flex>
                                        <Flex
                                            direction="row"
                                            align="center"
                                            width={["100%", "28%"]}  
                                            justify="flex-start"
                                        >
                                            <ChatIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                                            <Text fontWeight="bold" fontSize="sm">Comments:</Text>
                                            <Text fontSize="sm" color="gray.700" ml={2}>12</Text>
                                        </Flex>
                                        <Flex
                                            direction="row"
                                            align="center"
                                            width={["100%", "28%"]}   
                                            justify="flex-start"
                                        >
                                            <ThumbsDownIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                                            <Text fontWeight="bold" fontSize="sm">Dislikes:</Text>
                                            <Text fontSize="sm" color="gray.700" ml={2}>22</Text>
                                        </Flex>
                                    </Flex>
                                    <hr></hr>

                                </div>
                            ))}
                    </div>
                </div>

                {/* Main chat area */}
                <div className="col-sm-8 lecture-chat-list">
                    {selectedChatRoom ? (
                        <>
                            {isLoading ? (
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="loader"></span>
                                </div>
                            ) : roomData?.length > 0 ? (
                                roomData.map((message, i) => (
                                    <Flex key={i} align="center" my={2}>
                                        <Box mr={2}>
                                            <Avatar style={{ height: "30px", width: "30px" }} src={message?.user?.avatar} name={message?.user?.name} />
                                        </Box>
                                        <Box>
                                            <Text fontWeight="bold">{message?.user?.name}</Text>
                                            <Text>{message?.text}</Text>
                                            <Text fontSize="sm" color="gray.500">
                                                {moment(message?._id).format("MMMM Do YYYY, h:mm:ss a")}
                                            </Text>
                                            {/* <Button size="xs" onClick={() => handleReply(message._id)}>Reply</Button> */}

                                            {message?.replies !== undefined ? (
                                                <Box mt={2} pl={4} borderLeft="1px solid #e2e8f0">
                                                    <Text fontSize="sm" color="gray.500">Replies:</Text>
                                                    {Object.entries(message.replies).map(([key, reply]) => (
                                                        <Flex key={key} align="center" my={1}>
                                                            <Box mr={2}>
                                                                <Avatar style={{ height: "30px", width: "30px" }} src={reply?.user?.avatar} name={reply?.user?.name} />
                                                            </Box>
                                                            <Box>
                                                                <Text fontWeight="bold">{reply?.user?.name}</Text>
                                                                <Text>{reply?.text}</Text>
                                                                <Text fontSize="sm" color="gray.500">
                                                                    {moment(reply?._id).format("MMMM Do YYYY, h:mm:ss a")}
                                                                </Text>
                                                            </Box>
                                                        </Flex>
                                                    ))}
                                                </Box>
                                            ) : (
                                                <></>
                                            )}
                                        </Box>
                                    </Flex>
                                ))
                            ) : (
                                <Text style={{ textAlign: 'center', color: 'grey', marginTop: '5px' }}>
                                    No Chats Available
                                </Text>
                            )}
                        </>
                    ) : (
                        <Text style={{ textAlign: 'center', color: 'grey', marginTop: '5px' }}>
                            Select a lecture to see chats
                        </Text>
                    )}
                </div>
            </div>
        </div>
    );
};

const UpcomingList = ({ chatDetails }) => {
    const dispatch = useDispatch();
    const [selectedClasses, setSelectedClasses] = useState([]);

    const { user, getBatchSubjectLectureStatus } = useSelector((s) => ({
        user: s.user.user,
        getBatchSubjectLectureStatus: s.doubts.getBatchSubjectLectureStatus,
    }));

    useEffect(() => {
        if (getBatchSubjectLectureStatus === STATUS.SUCCESS) {
            setSelectedClasses([]);
        };
    }, [getBatchSubjectLectureStatus]);

    const handleBeginClass = (classId) => {
        let Classes = selectedClasses;
        if (Classes.includes(classId)) {
            console.log("working : ", classId)
            setSelectedClasses(Classes.filter(id => id !== classId));
        }
        dispatch(getBatchSubjectStatusAction({ id: [classId], status: "Live" }));
    };

    const _beginSelectedClasses = (classes) => {
        if (classes.length > 0) {
            dispatch(getBatchSubjectStatusAction({ id: classes, status: "Live" }));
        }
    };

    const _selectClass = (classId, e) => {
        let Classes = selectedClasses;
        if (Classes.includes(classId)) {
            setSelectedClasses(Classes.filter(id => id !== classId));
        }
        else {
            setSelectedClasses((classes) => [...classes, classId]);
        }
    };

    return (
        <div>
            {
                selectedClasses?.length > 0 ?
                    <div className="d-flex justify-content-end pb-4 ">
                        <Button onClick={() => { _beginSelectedClasses(selectedClasses) }}>Begin Selected Classes</Button>
                    </div>
                    : <></>
            }
            {
                chatDetails?.Upcoming.length > 0 &&
                chatDetails?.Upcoming.map((classDetail, i) => (
                    <Box key={i} style={{ border: "1px solid #8484843d", padding: "5px", borderRadius: "5px", marginBottom: "5px" }}>
                        <div className="d-flex align-items-center">
                            <input
                                id={`beginClass-${i + 1}`}
                                type="checkbox"
                                name="begin-class-checkbox"
                                onChange={(e) => { _selectClass(classDetail?._id, e) }}
                                checked={selectedClasses.includes(classDetail?._id)}
                                style={{
                                    width: "18px",
                                    height: "18px",
                                    display: "inline-block",
                                    marginRight: "12px",
                                    marginLeft: "5px",
                                    cursor: "pointer "
                                }} />
                            <div className="d-flex align-items-center justify-content-between " style={{ width: "calc(100% - 35px)" }}>
                                <div className="col-4">
                                    <div className="fs-6 fw-bold ">{classDetail?.lectureName}</div>
                                    <div>{classDetail?.batch}</div>
                                </div>
                                <div className="col-4 text-center">
                                    <div style={{ padding: "10px" }}>{moment(classDetail?.scheduleDateTime).format("MMMM Do YYYY, h:mm:ss a")}</div>
                                </div>
                                <div className="col-4 text-end">
                                    <Button onClick={() => handleBeginClass(classDetail?._id)}>Begin Class</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                ))}
        </div>
    );
};

const CustomTab = ({ title }) => {
    return (
        <Tab marginX={[1, 1, 1, 2]} paddingX={3} p={2} background="#EFF3F6" color={"black"} _selected={{ background: "brand.blue", color: "white", borderRadius: 4 }}>
            {title}
        </Tab>
    );
};

const MobileTeacherList = ({ resetSearch }) => {
    const params = useParams();
    const { teachers } = useSelector((state) => ({
        teachers: state.doubts.allTeachers,
    }));

    const [currentTeacher, setTeacher] = useState();
    const [openIndex, changeOpenIndex] = useState([0]);

    useEffect(() => {
        changeOpenIndex([]);
    }, [params.staffId]);

    useEffect(() => {
        if (params.staffId && teachers) {
            let staff = _.find(teachers, (t) => t.user?._id === params.staffId);
            setTeacher(staff);
        }
    }, [params, teachers]);

    const handleOpen = () => {
        changeOpenIndex((d) => (d.length ? [] : [0]));
    };

    return (
        <>
            <Accordion index={openIndex} allowMultiple {...mobileView}>
                <AccordionItem>
                    <AccordionButton onClick={handleOpen}>
                        <Box flex="1" textAlign="left">
                            <HStack justify={"space-between"}>
                                <Text color={"brand.blue"}>{currentTeacher?.user.name}</Text>
                                <Text>Teachers ({teachers?.length}) &nbsp;</Text>
                            </HStack>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <MemorizeTeachersList resetSearch={resetSearch} hideHeading />
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Flex justifyContent="flex-end" display={["flex", "flex", "flex", "none"]} my={2}>

            </Flex>
        </>
    );
};

const TeachersList = ({ hideHeading, resetSearch }) => {
    const history = useHistory();
    const params = useParams();

    const [allTeachers, changeTeachers] = useState([]);
    const [sortOn, setSortOn] = useState("doubt");
    const [sortType, changeSortType] = useState("asc");
    const { teachers } = useSelector((state) => ({
        teachers: state.doubts.allTeachers,
    }));

    useEffect(() => {
        if (teachers?.length) changeTeachers(teachers);
    }, [teachers]);

    useEffect(() => {
        handleSort();
    }, [sortOn, sortType]);


    const handleTeacher = (teach) => {

        resetSearch();
        history.push(`/doubts/1/${teach.user?._id}`);
    };

    const handleChange = (e) => {
        let searchText = e.target.value;
        let list = _.filter(teachers, (t) => _.includes(_.lowerCase(t?.user?.name), _.lowerCase(searchText)));
        changeTeachers(list);
    };
    const handleSort = () => {
        if (sortOn === "doubt") changeTeachers((d) => _.orderBy(d, (doubt) => (!doubt.doubtCount ? 0 : doubt.doubtCount), sortType));
        else changeTeachers((d) => _.orderBy(d, (doubt) => (!doubt.doubtCountResolved || !doubt.doubtCount ? 0 : doubt.doubtCountResolved / doubt.doubtCount), sortType));
    };
    return (
        <List h="100%">
            {hideHeading ? null : (
                <ListItem background={"gray.100"} borderRadius={4} py={2} px={3}>
                    <HStack>
                        <Text>Teachers</Text>
                        <Text>({allTeachers?.length || 0})</Text>
                    </HStack>
                </ListItem>
            )}
            <ListItem my={3}>
                <HStack>
                    <Box flex={1}>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none" children={<Search2Icon color="gray.300" />} />
                            <Input onChange={handleChange} placeholder="Search Teacher" />
                        </InputGroup>
                    </Box>
                </HStack>
            </ListItem>
            <ListItem my={3}>
                <Flex>
                    <Button
                        onClick={() => changeSortType((d) => (d === "asc" ? "desc" : "asc"))}
                        size="sm"
                        variant="outline"
                        colorScheme="blue"
                        width={"100%"}
                        mr={3}
                        rightIcon={sortType === "asc" ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    >
                        <Box>Sort </Box>
                    </Button>
                    <Menu>
                        <MenuButton as={Button} size="sm" px={4} colorScheme="blue" rightIcon={<ChevronDownIcon />}>
                            {sortOn === "doubt" ? "Count" : "Top"}
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => setSortOn("doubt")}>Doubt count</MenuItem>
                            <MenuItem onClick={() => setSortOn("top comment")}>Top</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </ListItem>
            <ListItem my={3}>
                <HStack>
                    <Box flex={1}></Box>
                </HStack>
            </ListItem>
            <List
                maxH="100vh"
                overflowY="scroll"
                overflowX="hidden"
                pos="sticky"
                top="0px"
                css={{ "&::-webkit-scrollbar": { width: "6px" }, "&::-webkit-scrollbar-thumb": { background: "#cdcdcd", borderRadius: "5px" } }}
            >
                {allTeachers?.length
                    ? allTeachers.map((teach) => {
                        const active = teach.user?._id === params.staffId;
                        return (
                            teach.user && (
                                <ListItem
                                    borderRadius={active ? 5 : 2}
                                    my={1}
                                    cursor={"pointer"}
                                    _hover={{ background: "blue.200", color: "white" }}
                                    py={1}
                                    px={2}
                                    key={teach._id}
                                    transition="all .3s"
                                    onClick={() => handleTeacher(teach)}
                                    background={active && "brand.blue"}
                                    color={active ? "white" : "brand.secondary"}
                                >
                                    <HStack justify={"space-between"} alignItems="flex-start">
                                        <Box pt={1}>
                                            <Text>{teach.user?.name}</Text>
                                            <Text fontSize="xs">{teach.staffDesc}</Text>
                                        </Box>
                                        <HStack display={"flex"}>

                                            <Box fontSize={11}>
                                                <Box>Doubts</Box>
                                                <Flex justifyContent="center">
                                                    <Box>{teach?.doubtCountResolved || 0}</Box>
                                                    <Box px={1}>/</Box>
                                                    <Box textAlign="left">{teach.doubtCount || 0}</Box>
                                                </Flex>
                                            </Box>
                                            {<ChevronRightIcon visibility={active ? "" : "hidden"} fontSize={24} color={"white"} />}
                                        </HStack>
                                    </HStack>
                                </ListItem>
                            )
                        );
                    })
                    : null}
            </List>
        </List>
    );
};

const MemorizeTeachersList = React.memo(TeachersList);

const DoubtItem = ({ onPress, d, i }) => {
    const dispatch = useDispatch();
    const [isCopied, setIsCopied] = useState(false);
    const doubtStatus = d.status === "Closed" ? { label: "Closed", color: "red" } : { label: d.status, color: "green" };

    const publicStatus = { label: "Public", color: "green" };

    const _open = () => {
        onPress(d);

    };
    const copyText = (text) => {
        if (!navigator || isCopied) return;
        navigator.clipboard.writeText(text);
        setIsCopied(true);
        window.setTimeout(() => {
            setIsCopied(false);
        }, 1300);
    };
    return (
        <Flex onClick={_open} p={2} px={4} _hover={{ bg: "gray.100", boxShadow: "sm" }} cursor="pointer" border="1px solid #dddddd" borderRadius={"md"} m={1}>
            <Box mr={5}>
                <Text fontSize="" color="gray.700">
                    {i}.
                </Text>
            </Box>
            <Box>
                <Flex>
                    <Text fontSize="sm" color="gray.600">
                        {d.userId?.name}
                    </Text>
                    <Text color="gray.500" fontSize="xs" ml={10}>
                        <i>{moment(d.createdAt).format("DD MMM YYYY hh:mm a")}</i>
                    </Text>
                </Flex>
                <Box pb={2} pt={1}>
                    <Text fontSize={"sm"}>{d.doubt}</Text>
                </Box>
                <HStack mt={3}>
                    <Tag size="sm" colorScheme={doubtStatus.color}>
                        {doubtStatus.label}
                    </Tag>
                    {d.public ? <Tag colorScheme={publicStatus.color}>{publicStatus.label}</Tag> : null}
                    <Box onClick={(e) => e.stopPropagation()}>
                        <Popover>
                            <PopoverTrigger>
                                <Tag _hover={{ boxShadow: "lg" }} size="sm" colorScheme="blue">
                                    <PhoneIcon color="blue.400" />
                                </Tag>
                            </PopoverTrigger>
                            <PopoverContent boxShadow="none!important">
                                <PopoverArrow />

                                <Flex justifyContent="space-between">
                                    <PopoverBody textAlign="center" flexGrow="1">
                                        {d.userId?.contact}
                                    </PopoverBody>
                                    <Button variant="ghost" mr={2} onClick={() => copyText(d.userId?.contact)}>
                                        {isCopied ? <CheckCircleIcon color="green.400" /> : <CopyIcon />}
                                    </Button>
                                </Flex>
                            </PopoverContent>
                        </Popover>
                    </Box>
                </HStack>
            </Box>
        </Flex>
    );
};

const DoubtDetails = ({ isOpen, onClose, currentDoubt, test, courseContent, checkRole }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(resetReadAction());
    }, [dispatch]);

    useEffect(() => {
        let itemId = test?.itemId || courseContent?.itemId;
        if (itemId && !checkRole) dispatch(readCommentAction({ itemId }));
    }, [dispatch, courseContent, test, checkRole]);

    return (
        <Drawer isOpen={isOpen} size={["xl"]} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent pt={10}>
                <DrawerCloseButton mt={10} />
                <DrawerHeader>
                    {test ? (
                        <Box alignItems="center">
                            <Box color="gray.800">{test.itemName}</Box>

                        </Box>
                    ) : courseContent ? (
                        <Box alignItems="center">
                            <Box color="gray.800">{courseContent.itemName}</Box>

                        </Box>
                    ) : (
                        <Box alignItems="center">
                            <Box color="gray.800">{currentDoubt.user?.name}</Box>
                            <Box fontSize={13} color="gray.500">
                                <i>{moment(currentDoubt.createdAt).format("DD MMM YYYY, hh mm a")}</i>
                            </Box>
                        </Box>
                    )}

                </DrawerHeader>

                <DrawerBody style={{ maxHeight: "100vh" }}>
                    {test ? (
                        <Box>

                            <DiscussionComments
                                onBottomAtSmall={true}

                                commentsLabel="Comments"
                                showAttachment={false}
                                placeholder="Add a comment"
                                inputStyle="flushed"
                                itemId={test.itemId}
                                includeDoubt={2}
                            />
                        </Box>
                    ) : courseContent?.content ? (
                        <Box>

                            <DiscussionComments
                                onBottomAtSmall={true}

                                commentsLabel="Comments"
                                showAttachment={false}
                                placeholder="Add a comment"
                                inputStyle="flushed"
                                itemId={courseContent.itemId}
                                itemModel={courseContent.itemModel}
                                contentId={courseContent.content}
                                courseId={courseContent.course}
                                includeDoubt={2}
                            />
                        </Box>
                    ) : (
                        <DoubtComponent currentDoubt={currentDoubt} />
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export const DoubtComponent = ({ currentDoubt }) => {
    const dispatch = useDispatch();

    const { closeDoubtStatus, publicDoubtStatus, user } = useSelector((state) => ({
        closeDoubtStatus: state.doubts.closeDoubtStatus,
        publicDoubtStatus: state.doubts.publicDoubtStatus,
        user: state.user.user,
    }));

    const checkRole = useMemo(() => {
        return user?.staff?.staffRole === "ADMIN" || user?.staff?.staffRole === "HEAD_TEACHER" || user?.staff?.staffRole === "TEACHER";
    }, [user?.staff?.staffRole]);

    const handleClose = () => {
        let data = {
            status: "Closed",
            doubtId: currentDoubt._id,
            closedDate: moment().format(),
        };
        dispatch(closeDoubtAction(data));
    };

    const handlePublic = () => {
        let data = {
            doubtId: currentDoubt._id,
            public: currentDoubt.public ? false : true,
        };
        dispatch(publicDoubtAction(data));
    };

    return (
        <>
            <HStack>
                <Button disabled={currentDoubt.status === "Closed"} size="sm" isLoading={closeDoubtStatus === STATUS.FETCHING} onClick={handleClose} variant="outline" color={"brand.red"}>
                    {currentDoubt.status === "Closed" ? "Closed" : "Close Doubt"}
                </Button>
                <Button
                    size="sm"
                    isLoading={publicDoubtStatus === STATUS.FETCHING}
                    variant="outline"
                    onClick={handlePublic}
                    color={currentDoubt.public ? "brand.red" : "brand.green"}
                    disabled={currentDoubt.status === "Open"}
                >
                    {currentDoubt.public ? "Make it Private" : "Make it Public"}
                </Button>
            </HStack>
            <br />
            <Box
                fontSize="md"
                color={"brand.secondary"}
                fontFamily='"Lato", sans-serif'
                as="pre"
                py={3}
                sx={{
                    "white-space": "-moz-pre-wrap",
                    "white-space": "-o-pre-wrap",
                    "word-wrap": "break-word",
                    "white-space": "pre-wrap",
                }}
            >
                {currentDoubt.doubt}
            </Box>
            <br />
            <br />
            <Box>
                <DiscussionComments
                    onBottomAtSmall={true}
                    editCommentUserId={checkRole && user._id}
                    commentsLabel="Comments"
                    showAttachment={true}
                    placeholder="Add a comment"
                    inputStyle="flushed"
                    itemId={currentDoubt._id}
                />
            </Box>
        </>
    );
};
